<template>
  <div class="header">
    <div class="header_content flex_sb">
      <div class="header_logo" @click="toPage('/index')"><img src="../assets/image/logo.png" alt=""></div>
      <div class="header_menu" @click="menuFlag=!menuFlag">
        <span class="hamb_top" v-if="!menuFlag"></span>
        <span class="hamb_middle" :class="[menuFlag?'downLine':'']"></span>
        <span class="hamb_bottom" :class="[menuFlag?'upLine':'']"></span> 
      </div>
      <div class="draw_menu" :class="[!menuFlag?'show_draw_menu':'']" @click="menuFlag=!menuFlag">
          <div class="draw_menu_drop" >
            <div class="draw_menu_pop" :class="[menuFlag?'draw_menu_popdown':'']">
              <div class="draw_menu_tab flex_sb" v-for="(item,index) in menuList" :key="index" @click="toPage(item.path)" v-show="token?item.loginFlag:true">
                <div class="menu_title">{{item.title}}</div>
                <div class="icon_left"><svg t="1729158554238" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3634" width="16" height="16"><path d="M383.291616 808.954249c-5.175883 0-10.353812-1.950422-14.338566-5.862521-8.064676-7.919367-8.182356-20.877493-0.26299-28.942169l273.602402-278.620695L368.69006 216.907145c-7.919367-8.064676-7.801686-21.022803 0.26299-28.942169 8.065699-7.918343 21.022803-7.80271 28.942169 0.26299l287.685141 292.960285c7.818059 7.961322 7.818059 20.717857 0 28.67918L397.895219 802.826692C393.887952 806.907637 388.591319 808.954249 383.291616 808.954249z" p-id="3635" fill="#16181c"></path></svg></div>
              </div>
            </div>
          </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import cookies from '../utils/cookie';
export default {
  data () {
    return {
      menuFlag:false,
      menuList:[
        {id:1,title:'Signup',path:'/register',loginFlag:false},
        {id:2,title:'Login',path:'/login',loginFlag:false},
        {id:3,title:'Game',path:'/index',loginFlag:true},
        {id:4,title:'Order',path:'/order',loginFlag:true},
        {id:5,title:'Me',path:'/personal',loginFlag:true},
      ]
    }
  },
  methods: {


    toPage(path){
      this.$router.push(path)
    }
  },
  computed:{
    token(){ return cookies.getToken()}
  }
}
</script>

<style lang="scss" >
.header {
  // width: 100%;
  color:#16181c;
  height: 60px;
  font-size: 16px;
  background: #fae44c;
  position: absolute;
  left:0;
  right:0;
  top:0;
  padding:12px 16px 0;
  z-index: 99;
  .header_content{
    height: 34px;
    position: relative;
    .header_logo{
      height: 100%;
      >img{
        height: 100%;
        width:auto;
      }
    }
    .header_menu{
      position: relative;
      width: 22px;
      height: 22px;
      >span{
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
        background-color:#16181c;
        transition: .35s;
      }
      .hamb_top{
        
        top:2px;
      }
      .hamb_middle{
       top:calc(50% - 2px);
      }
      .hamb_bottom{
       bottom:2px;
      }
      .downLine{
        transform: rotate(45deg);
        transition: transform .2s cubic-bezier(.73,1,.28,.08);
        top: 50%;
      }
      .upLine{
        transform: rotate(-45deg);
        transition: transform .2s cubic-bezier(.73,1,.28,.08);
        top: 50%;
      }
      
    }
    .show_draw_menu{
      opacity: 0;
      height: 0!important;
    }
    .draw_menu{
       transition: all 0.35s ease-in-out;
        position: absolute;
        left:-16px;
        top:48px;
        right: -16px;
        // width: 400px;
        height: 92vh;
        z-index: 9999;
        background-color: rgba(0, 0, 0, .7);
        .draw_menu_pop{
          transition: all 0.35s ease-in-out;
          // height: 0;
          background:#fae44c;
          border-radius: 0 0 0px 8px;
          width: 100%;
          overflow: hidden;
          .draw_menu_tab{
            padding:0px 16px;
           
            // height: 46px;
            transition: all 0.35s ease-in-out;
            height: 0;
            overflow: hidden;
            border:none;
            position: relative;
            &::before{
              content:'';
              position: absolute;
              left:16px;
              right:16px;
              height: 1px;
              bottom:0;
              background:rgb(184, 168, 58);
            }
          }
        }
        .draw_menu_popdown{
          height: auto;
         >div{
          height: 46px!important;
          
         }
        }
      }
  }
  
  @keyframes movein {
    0% {
      transform: scale(1); /*开始为原始大小*/
    }
    100% {
      transform: scale(1.1); /*放大1.1倍*/
    }
  }
  .animate_small {
    animation: moveout 0.2s infinite;
    animation-direction: normal; /*轮流反向播放动画。*/
    animation-timing-function: ease-in-out; /*动画的速度曲线*/
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    /* Safari 和 Chrome */
    -webkit-animation: moveout 0.2s infinite; /*Safari and Chrome*/
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-direction: normal; /*轮流反向播放动画。*/
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
  }
  @keyframes moveout {
    0% {
      transform: scale(1.1); /*开始为原始大小*/
    }
    100% {
      transform: scale(1); /*放大1.1倍*/
    }
  }
}
</style>